<template>
  <v-card>
    <v-card-title class="justify-center">
      <!--
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      
      <v-pagination
        v-model="page"
        :length="pages"
      ></v-pagination>
      -->
      
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="datos"
      :search="search"
      hide-default-footer
    ></v-data-table>

    <v-pagination
        v-model="page"
        :length="pages"
        v-on:input="fetchData()"
      ></v-pagination>

  </v-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      url : process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      headers: [
        { text: "tipo", align: "start", value: "tipo" },
        { text: "descripcion", value: "descripcion" },
        { text: "total", value: "ct" },
        { text: "2A", value: "cr2a" },
        { text: "3A-5A", value: "cr3a5a" },
        { text: "6A-10A", value: "cr6a10a" },
        { text: "+10A", value: "crM10a" },
        { text: "Dep. Adicional", value: "cda" },
        { text: "MARCELA 753", value: "p1" },
        { text: "OSCAR 739", value: "p2" },
        { text: "RAFAEL 759", value: "p3" },
        { text: "VLADIMIR 2612", value: "p4" },
        { text: "RENATO 1101", value: "p5" },
        { text: "ROBERTO 2664", value: "p6" },
        { text: "CARLOS FUENTES 12761", value: "p7" },
        { text: "EDUARDO VEGA 11049", value: "p8" },
        { text: "JUAN DIEGO PERE 11939", value: "p9" },
        { text: "SAMA 11987", value: "p10" },
        { text: "XAVIER CAMACHO 10431", value: "p11" },
        { text: "XAVIER NARVAEZ 11940", value: "p12" },
        { text: "MARGARITA SERRANO 2748", value: "p13" },
        { text: "VERONICA CINDRIC 2758", value: "p14" },
        { text: "JOSE ESTUPIÑAN 12492", value: "p15" },
        { text: "ZOVEIDA 11940", value: "p16" },
        { text: "BORIS KUSIJANOVIC", value: "p17" },
      ],
      desserts: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: "1%"
        }
      ]
    };
  },
  methods: {
    fetchData() {
      console.log('llamando api')
      //axios.get("data/datos.json").then(response => {
      axios.get(this.url+'/comisiones-base/paginado?page='+this.page+'&limit=10').then(response => {
        this.datos = response.data?.items || [];
        this.pages = response.data?.meta?.totalPages || 0;
      });
    },

  },
  created() {
    this.fetchData();
  }
};
</script>
